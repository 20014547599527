import { useEffect, useContext, useState } from 'react';
import '../App.css';
import { Link, NavLink } from "react-router-dom";
import moment from 'moment';
import { AuthContext, PlantContext } from '../utils/constants.js';
import { io } from "socket.io-client";

function SideNav() {
  const plant = useContext("PlantContext");
  return(
    <>
    <div className="sidebar">
      <div className="menu_items">
        <ul>
          <li>
            <NavLink to="/">
              <img src="assets/Dashboard-active.svg" alt="Home" />
              <label>Home</label>
            </NavLink>
          </li>
          {"1"==="1" &&
            <li>
              <NavLink to="/inverter">
                <img src="assets/Inverter-active.svg" alt="Home" />
                <label>Inverter</label>
              </NavLink>
            </li>
          }
          <>
            {"1"==="1" &&
              <li>
                <NavLink to="/smb">
                  <img src="assets/SMB.svg" alt="Home" />
                  <label>SMB</label>
                </NavLink>
              </li>
            }
          </>
          <li>
            <NavLink to="/report">
              <img src="assets/Trend.svg" alt="Home" />
              <label>Report</label>
            </NavLink>
          </li>
          {/*
          <li>
            <NavLink to="/alarm">
              <img src="assets/Alarm-active.svg" alt="Home" />
              <label>Alram</label>
            </NavLink>
          </li> 
          <li>
            <NavLink to="/">
              <img src="assets/WMS-active.svg" alt="Home" />
              <label>WMS</label>
            </NavLink>
          </li>
          <li>
            <NavLink to="/">
              <img src="assets/Trend-active.svg" alt="Home" />
              <label>Trend</label>
            </NavLink>
          </li>
          <li>
            <NavLink to="/">
              <img src="assets/IP LIST-active.svg" alt="Home" />
              <label>IP List</label>
            </NavLink>
          </li>
          */}
        </ul>
      </div>
    </div>
    </>
  );
}

function Header() {
  const plant = useContext(PlantContext);
  var username = sessionStorage.getItem("userName");
  return(
    <>
    {/*<!-- Header-->*/}
    <div className="container-fluid header_container p-1 position-fixed">
      <div className="row align-items-baseline mx-0">
        <div className="col brand_logo align-items-start img-fluid">
          <img src="assets/cyspl-logo.png" alt="Brand Logo" />
        </div>
        <div className="col selection_type">
          <h4>{plant.name}</h4>
        </div>
        <div className="col h_time">
          <label><span>{moment().format("lll")}</span></label>
        </div>
      </div>
    </div>
    {/*<!-- Header End-->*/}
    </>
  );
}

function InvHeader() {
  const plant = useContext(PlantContext);
  const [data, setData] = useState({});
  const [wmsData, setWmsData]= useState({});
 
  useEffect(()=>{
    const socket = io();

    socket.on("inverterdata/plant/" + plant.id, (result) => {
      var data = result.inverterData.reduce((a,c)=>{
        a.TodayKWH += c.TodayKWH;
        a.TotalKWH += c.TotalKWH;
        a.ActivePower += c.ActivePower;
        a.DCVoltage += ((c.DCVoltage * c.DCCurrent)/1000);
        return a;
      }, {TodayKWH: 0, TotalKWH: 0, ActivePower: 0, DCVoltage: 0})
      setData(data);     
    });

    socket.on("wms_data/plant/" + plant.id, (result)=> {
      console.log(result);
      setWmsData(result);
    });

    return () => {
      socket.disconnect();
    };
  },[]);

  return(
    <div className="eng_info">
      <div className="col eng_value">
        <h6>Daily Power Supplied [kWH]</h6>
        <label>{data.TodayKWH}</label>
      </div>
      <div className="col eng_value">
        <h6>Total Power Supplied [kWH]</h6>
        <label>{data.TotalKWH}</label>
      </div>
      <div className="col eng_value">
        <h6>DC Input <br/> [kW]</h6>
        <label>{data.DCVoltage?(data.DCVoltage).toFixed(1):0}</label>
      </div>
      <div className="col eng_value">
        <h6>Active Power <br />[KW]</h6>
        <label className="eng_range"><img src="assets/thunder.svg" />{data.ActivePower}</label>
      </div>
      <div className="col eng_value">
        <h6> Weather <br />[°C]</h6>
        <label>{wmsData.Temperature?wmsData.Temperature:""}</label>
      </div>
      <div className="col eng_value">
        <h6> Irradiation Power<br />[kWH]</h6>
        <label>{wmsData.IrradiancePower?wmsData.IrradiancePower:""}</label>
      </div>

      <div className="col eng_value kpi-state">
        <h6>DEVICES</h6>
      </div>

      <div className="col eng_value eng_spacing">
        <h6>Inverter <span className="kpi_value">{plant.inverters}</span></h6>
        <h6>SMB <span className="kpi_value">{plant.smbs}</span></h6>
        <h6>MFM <span className="kpi_value">{plant.mfms}</span></h6>
      </div>

      <div className="col eng_value eng_value_align ">
        <img src="assets/cyspl-logo.png" alt="Brand logo" className="img-fluid" style={{width: '50%', margin: "0 auto"}} />
      </div>
    </div>
  );
}

function AdminTopBar(props) {
  const { signOut } = useContext(AuthContext);
  const current_user = sessionStorage.getItem("userName");
  const navbarClass = props.sidebarExpand?"navbar fixed-top navbar-expand-md bg-light nav-exp":"navbar fixed-top navbar-expand-md bg-light";
  return(
    <div>
      <nav className={navbarClass}>
        <div className="container-fluid">
          <button className="btn btn-light mx-2" onClick={props.collapseSideNav} >
            <i className="fa-solid fa-bars"></i>
          </button>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item dropdown">
                <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {current_user} <i className="fa-solid fa-user"></i>
                </Link>
                <ul className="dropdown-menu dropdown-menu-end">
                  <li><button className="dropdown-item dropdown_item-3" onClick={signOut}>
                    <i className="fa-solid fa-arrow-right-from-bracket"></i> Logout
                  </button></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

const AdminSideNav = (props) => {
  return (
    <div>
      <aside id="sidenav" className={props.sidebarExpand?"sidenav-expand":"sidenav-collapse"}>
          <div className="d-flex align-items-center p-2 branding-area">
            <img className="logo" src="/logo.jpg" alt="logo" />
            <h5 className="branding mb-0 ms-3 fw-bold">Macsoft</h5>
          </div>
          <div id="LeftScrollableMenus">
            <div className="menu-container">
              <ul id="sidenav-links">
                <li className="sidenav-item p-3">
                  <NavLink to="/" className="d-flex align-items-center sidenav-link">
                    <i className="fa-solid fa-gauge"></i>
                    <span className="nav-link-text">Dashboard </span>
                  </NavLink>
                </li>
                <li className="sidenav-item p-3">
                  <NavLink to="/parks" className="d-flex align-items-center sidenav-link">
                    <i className="fa-solid fa-solar-panel"></i>
                    <span className="nav-link-text">Parks </span>
                  </NavLink>
                </li>                
                <li className="sidenav-item p-3">
                  <NavLink to="/plants" className="d-flex align-items-center sidenav-link">
                    <i className="fa-solid fa-industry"></i>
                    <span className="nav-link-text">Plants </span>
                  </NavLink>
                </li>                      
                <li className="sidenav-item p-3">
                  <NavLink to="/inverters" className="d-flex align-items-center sidenav-link">
                    <i className="fa-solid fa-list-check"></i>
                    <span className="nav-link-text">Inverters</span>
                  </NavLink>
                </li>
                <li className="sidenav-item p-3">
                  <NavLink to="/smbs" className="d-flex align-items-center sidenav-link">
                    <i className="fa-solid fa-cash-register"></i>
                    <span className="nav-link-text">SMBs </span>
                  </NavLink>
                </li>
                <li className="sidenav-item p-3">
                  <NavLink to="/mfms" className="d-flex align-items-center sidenav-link">
                    <i className="fa-regular fa-file-lines"></i>
                    <span className="nav-link-text">MFMs </span>
                  </NavLink>
                </li>
                <li className="sidenav-item p-3">
                  <NavLink to="/users" className="d-flex align-items-center sidenav-link">
                    <i className="fa-solid fa-users"></i>
                    <span className="nav-link-text">Users </span>
                  </NavLink>
                </li>
                <li className="sidenav-item p-3">
                  <NavLink to="/userplants" className="d-flex align-items-center sidenav-link">
                    <i className="fa-solid fa-key"></i>
                    <span className="nav-link-text">User Access</span>
                  </NavLink>
                </li>                          
              </ul>
            </div>
          </div>
      </aside>
    </div>
  );
}

const Preloader = ()=> {
  return(
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  );
}

export { SideNav, Header, InvHeader, AdminTopBar, AdminSideNav, Preloader };
